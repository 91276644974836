<template>
  <b-overlay
    :show="showLoading"
    variant="dark"
    :opacity="0.25"
    rounded="sm"
  >
    <b-card
      no-body
      class="p-3"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer ref="formRef">
            <b-form @submit.prevent>

              <b-row>

                <!-- Code Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('apps.transactionsCashAdvance.singular.code')"
                    label-for="code"
                  >
                    <b-form-input
                      id="code"
                      :placeholder="$t('apps.transactionsCashAdvance.placeholder.code')"
                      v-model="form.code"
                      :disabled="actions.isPreview"
                    />
                  </b-form-group>
                </b-col>

                <!-- Date Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('apps.transactionsCashAdvance.singular.date')"
                    label-for="date"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="date"
                      rules="required"
                    >
                      <flat-pickr
                        id="date"
                        :config="dateFormat"
                        v-model="form.date"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Cash Advance Code Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('apps.transactionsCashAdvance.singular.cashAdvanceCode')"
                    label-for="cash-advance-code"
                  >
                    <b-form-input
                      id="cash-advance-code"
                      v-model="form.cashAdvanceCode"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Employee Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('apps.transactionsCashAdvance.singular.employee')"
                    label-for="employee-id"
                  >
                    <v-select
                      id="employee-id"
                      v-model="form.employeeId"
                      :options="LOV.employees"
                      :reduce="field => field.id"
                      label="label"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>

                <!-- Type Field -->
                <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('apps.transactionsCashAdvance.singular.type')"
                    label-for="type-code"
                  >
                    <b-form-input
                      id="type-code"
                      v-model="form.typeName"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>

                <!-- Realization Type -->
                <!-- Shut it off, cause not yet ready -->
                <!-- <b-col cols="12" md="4">
                  <b-form-group
                    :label="$t('globalSingular.realizationType')"
                    label-for="realizationType"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="realizationType"
                      rules="required"
                    >
                      <v-select
                        id="realizationType"
                        class="select-size-sm"
                        v-model="form.realizationTypes"
                        :options="LOV.realizationTypes"
                        :reduce="field => field.code"
                        label="name"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->

                <!-- Cash Advance Code Field -->
                <b-col cols="12" md="4" v-if="form.realizationTypes">
                  <b-form-group
                    label="No Document"
                    label-for="documentNo"
                  >
                    <b-form-input
                      id="documentNo"
                      v-model="form.documentCode"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-if="form.realizationTypes">
                  <b-button block variant="primary" class="mt-2 pd-1" :disabled="!form.documentCode">
                    Add
                  </b-button>
                </b-col>

              </b-row>

              <!-- Description Field -->
              <b-form-group
                :label="$t('apps.transactionsCashAdvance.singular.notes')"
                label-for="notes"
              >
                <b-form-textarea
                  id="notes"
                  v-model="form.description"
                  :disabled="actions.isPreview"
                />
              </b-form-group>

              <b-row>
                <!-- Cash Advance Code Field -->
                <b-col cols="6" md="2">
                  <b-form-group
                    :label="$t('apps.transactionsCashAdvance.singular.refund')"
                    label-for="refund"
                  >
                    <b-form-input
                      id="cash-advance-code"
                      v-model="refund"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-table
                id="refFormTable"
                ref="refFormTable"
                small
                fixed
                no-provider-paging
                no-provider-filtering
                :fields="tableColumns"
                :items="form.details"
                primary-key="id"
                show-empty
                class="mt-2 position-relative"
              >
                <!-- Custom Header Column -->
                <template #head(actions)>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="mx-auto"
                  />
                </template>

                <!-- Styling width -->
                <!-- Column: Account -->
                <template #cell(account)="{ index }">
                  <b-form-group>
                    <v-select
                      id="account"
                      v-model="form.details[index].accountId"
                      :options="LOV.accounts"
                      :reduce="field => field.id"
                      label="label"
                      :disabled="true"
                    />
                  </b-form-group>
                </template>

                <!-- Column: Description -->
                <template #cell(description)="{ index }">
                  <b-form-group>
                    <b-form-input
                      v-model="form.details[index].description"
                      :disabled="true"
                    />
                  </b-form-group>
                </template>

                <!-- Column: Amount -->
                <template #cell(amount)="{ index }">
                  <b-form-group>
                    <cleave
                      :key="index"
                      v-model.number="form.details[index].amount"
                      class="text-right form-control"
                      :options="numeric"
                      :disabled="true"
                    />
                  </b-form-group>
                </template>

                <!-- Column: Realized Amount -->
                <template #cell(realizedAmount)="{ index }">
                  <b-form-group>
                    <cleave
                      :key="index"
                      v-model.number="form.details[index].realizedAmount"
                      class="text-right form-control"
                      :options="numeric"
                      :disabled="true"
                    />
                  </b-form-group>
                </template>

                <!-- Column: Realisation Amount -->
                <template #cell(realisationAmount)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="realisationAmount"
                      :vid="`details-${index}`"
                      :rules="`${!actions.isPreview ? 'required|max_value:' + form.details[index].balanceAmount : ''}`"
                    >
                      <cleave
                        :key="index"
                        v-model.number="form.details[index].realisationAmount"
                        :state="errors.length > 0 ? false : null"
                        class="text-right form-control"
                        :options="numeric"
                        @blur="sumRealisationAmount()"
                        :disabled="actions.isPreview || form.details[index].realizedAmount === form.details[index].amount"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>

                <!-- Costum Footer -->
                <template v-slot:custom-foot="{}">
                  <b-tr>
                    <b-td class="text-right" colspan="2">
                      <div class="mb-0 h4 font-weight-bolder">Total</div>
                    </b-td>
                    <b-td class="text-right">
                      <div class="mb-0 h4 font-weight-bolder">
                        <cleave
                          v-model.number="form.amount"
                          class="text-right form-control d-none"
                          :options="numeric"
                        />
                        {{ totalAmount }}
                      </div>
                    </b-td>
                    <b-td class="text-right">
                      <div class="mb-0 h4 font-weight-bolder">
                        <cleave
                          v-model.number="form.realizedAmount"
                          class="text-right form-control d-none"
                          :options="numeric"
                        />
                        {{ totalRealizedAmount }}
                      </div>
                    </b-td>
                    <b-td class="text-right">
                      <div class="mb-0 h4 font-weight-bolder">
                        <cleave
                          v-model.number="form.realisationAmount"
                          class="text-right form-control d-none"
                          :options="numeric"
                        />
                        {{ totalRealisationAmount }}
                      </div>
                    </b-td>
                  </b-tr>
                </template>
              </b-table>
                <!-- inv referensi -->
                  <b-table
                  id="refFormTable2"
                  ref="refFormTable2"
                  small
                  fixed
                  no-provider-paging
                  no-provider-filtering
                  :fields="tableColumnsInv"
                  :items="form.detailsinv"
                  primary-key="id"
                  show-empty
                  class="position-relative table-sm"
                >
                  <!-- Custom Header Column -->
                  <template #head(actions)>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      class="mx-auto"
                    />
                  </template>

                <template #cell(type)="{ index }">
                    <b-form-group>
                        <v-select
                        id="type"
                        v-model="form.detailsinv[index].type"
                        :options="LOV.inv"
                        :reduce="field => field.value"
                        label="label"
                        :disabled="actions.isPreview"
                        class="select-size-sm"
                        @option:selected="selectType(index,form.detailsinv[index].type)"
                        />
                    </b-form-group>
                  </template>

                  <!-- Column: Description -->
                  <template #cell(inv)="{ index }">
                    <b-form-group>
                      <v-select
                        id="type"
                        v-model="form.detailsinv[index].inv"
                        :options="LOV.doc_number"
                        :reduce="field => field.id"
                        label="code"
                        :disabled="actions.isPreview"
                        class="select-size-sm"
                        />
                    </b-form-group>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="{ index }">
                    <b-button
                      variant="flat-danger"
                      class="btn-icon invoice-edit-input"
                      :disabled="form.detailsinv.length <= 1"
                      @click="removeRowInv(index)"
                    >
                      <feather-icon icon="MinusIcon" />
                    </b-button>
                  </template>

                                  <!-- Costum Footer -->
                  <template v-slot:custom-foot="{}">
                    <b-tr>
                      <b-td colspan="3" class="p-0">
                        <b-button
                          v-if="!actions.isPreview"
                          variant="outline-primary"
                          block
                          @click="addRow"
                          size="sm"
                        >
                          <feather-icon
                            icon="PlusIcon"
                          />
                          {{ $t('globalActions.addMore') }}
                        </b-button>
                      </b-td>
                    </b-tr>
                  </template>

                  </b-table>

              <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
                <b-button
                  class="mb-sm-1 mb-md-0"
                  :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                  :to="{ name: 'apps-transactions-cash-advance-detail', params: { id: paramsId } }"
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                  />
                  {{ $t('globalActions.backToList') }}
                </b-button>

                <b-button
                  v-if="!actions.isPreview"
                  variant="primary"
                  type="submit"
                  @click="handleSubmit"
                >
                  <feather-icon
                    icon="SaveIcon"
                  />
                  {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
                </b-button>


                <b-button
                  v-if="actions.isPreview"
                  variant="outline-primary"
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                  {{ $t('apps.transactionsCashAdvance.actions.realisation') }}
                </b-button>

              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BTr,
  BTd,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import {ref, onMounted} from '@vue/composition-api'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import {required} from '@validations'
import {formatCurrency,formatDatepicker} from '@/utils/formatter'

import useFormResource from '@/comp-functions/useFormResource'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BTr,
    BTd,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    Cleave
  },
  setup () {
    const {
      paramsId,
      formRef,
      actions,
      changeToEdit,
      get,
      customStore,
      destroy
    } = useFormResource({
      url: 'transaction/cash-advance',
      localeContextPath: 'apps.transactionsCashAdvance.singular.cashAdvance',
      redirectPathName: 'apps-transactions-cash-advance-list'
    })

    const balanceAmount = ref(0)

    const form = ref({
      code: '',
      date: formatDatepicker(Date.now()),
      cashAdvanceCode: '',
      documentCode: '',
      employeeId: '',
      description: '',
      typeCode: '',
      typeName: '',
      amount: '',
      realizationTypes: null,
      balanceAmount: 0,
      realisationAmount: 0,
      realizedAmount : 0,
      details: [],
      detailsinv: [{ type: '', inv: '' }],
    })
    const showLoading = ref(false)

    const refund = ref()
  // Add row details
    const addRow = () => {
      form.value.detailsinv.push({ type: '', inv: ''})
    }
    const tableColumnsInv = ref([])

    // Remove row details
    const removeRowInv =  (index) => {
      if (form.value.detailsinv.length <= 1) return false
      form.value.detailsinv.splice(index, 1)
    }


    const LOV = ref({
      types: [
        { code: 'CAOP', name: 'CASH ADVANCE' },
        { code: 'CAPR', name: 'PERSONAL' }
      ],
      realizationTypes: [
        { code: 'PICKUP', name: 'PICKUP' },
        { code: 'OUTGOING', name: 'OUTGOING' },
        { code: 'TRANSIT', name: 'TRANSIT' },
        { code: 'INCOMING', name: 'INCOMING' },
        { code: 'DELIVERY', name: 'DELIVERY' }
      ],
      employees: [],
      accounts: [],
      inv: [
        { value: 'ops', label: 'OPS' },
        { value: 'bacc', label: 'BACC' },
        { value: 'Purchase_invoice', label: 'Purchase Invoice' },
      ],
      doc_number:[]
    })

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true
    })

    const totalRealisationAmount = ref(0)
    const totalRealizedAmount = ref(0)
    const totalAmount = ref(0)
    const sumRealisationAmount = () => {
      // sum realisation amount
      totalRealisationAmount.value = form.value.details.reduce((accumulator, {realisationAmount}) => accumulator + (realisationAmount), 0)
      form.value.realisationAmount = totalRealisationAmount.value

      // update balance master
      form.value.balanceAmount = parseInt(form.value.amount) - (totalRealisationAmount.value + form.value.realizedAmount)

      totalRealisationAmount.value = formatCurrency(totalRealisationAmount.value)
    }

    const dateFormat = ref({ dateFormat: 'd/m/Y', allowInput: true })

    const tableColumns = ref([])

    const getEmployees = async () => {
      LOV.value.employees = await get({ url: 'master/contact?type={CTKR}'})
    }

    const getAccounts = async () => {
      LOV.value.accounts = await get({ url: 'master/accounts'})
    }

    onMounted(() => {
      getAccounts()
      getEmployees()

      tableColumnsInv.value = [
        { key: 'type', label: 'Type', width: 'auto', thClass: '', tdClass: 'py-1 align-top' },
        { key: 'inv', label: 'Doc Number', width: 'auto', thClass: '', tdClass: 'py-1 align-top'},
        { key: 'actions', width: '12%', thClass: 'text-center width-10-per', tdClass: 'py-1 align-top text-center'}
      ]

      tableColumns.value = [
        {key: 'account', width: 'auto', label: 'Account', thClass: 'bg-transparent', tdClass: 'align-top'},
        {key: 'description', width: 'auto', label: 'Description', thClass: 'bg-transparent', tdClass: 'align-top'},
        {key: 'amount', width: 'auto', label: 'Amount', thClass: 'bg-transparent width-15-per', tdClass: 'align-top text-right'},
        {key: 'realizedAmount', width: 'auto', label: 'Total Realisation', thClass: 'bg-transparent width-15-per', tdClass: 'align-top text-right'},
        {key: 'realisationAmount', width: 'auto', label: 'Realisation Amount', thClass: 'bg-transparent width-15-per', tdClass: 'align-top text-right'}
      ]
    })

    const selectType = async (index, selected) => {
      console.log('----->>>>',index, selected)
      LOV.value.doc_number = await get({ url: 'value/getnumberInvoice'})
    }


    return {
      selectType,
      balanceAmount,
      form,
      showLoading,
      numeric,
      paramsId,
      formRef,
      removeRowInv,
      get,
      customStore,
      destroy,
      actions,
      changeToEdit,
      LOV,
      required,
      dateFormat,
      tableColumns,
      totalAmount,
      totalRealizedAmount,
      totalRealisationAmount,
      sumRealisationAmount,
      tableColumnsInv,
      addRow,
      refund
    }
  },


  async mounted () {
    if (this.paramsId) {
      this.showLoading = true
      this.actions.isPreview = true
      await this.getDataPreview()
      this.showLoading = false
    }
  },


  methods: {
    async getDataPreview () {
      const data = await this.get({
        url: `transaction/cash-advance/${this.paramsId}`
      })

      this.form.cashAdvanceCode = data.code
      this.form.employeeId = data.employee_id
      this.form.typeCode = data.type_code
      this.form.typeName = data.type.name
      this.form.amount = data.amount
      this.form.balanceAmount = parseInt(data.balance_amount ?? 0)
      this.balanceAmount = parseInt(data.balance_amount ?? 0)
      this.form.realisationAmount = data.realisation_amount
      this.form.realizedAmount = data.realisation_amount
      this.refund = formatCurrency(data.refund_amount)

      this.totalAmount = formatCurrency(data.amount)
      this.totalRealizedAmount = formatCurrency(data.realisation_amount)

      await Promise.all(
        this.form.details = data.details.map(field => {
          const detail = {}
          detail.id = field.id
          detail.employeeId = field.employee_id
          detail.accountId = field.account_id
          detail.description = field.description
          detail.amount = field.amount
          detail.balanceAmount = parseInt(field.amount) - parseInt(field.realisation_amount ?? 0)
          detail.realizedAmount = field.realisation_amount
          detail.realisationAmount = 0
          return detail
        })
      )

      this.sumRealisationAmount()
      this.actions.isPreview = false
      this.actions.isEditMode = true
    },

    async handleDelete () {
      this.showLoading = true
      await this.destroy(this.$swal)
      this.showLoading = false
    },

    async handleSubmit () {
      this.showLoading = true
      await this.customStore({
        $swal: this.$swal,
        data: this.form,
        url: `transaction/cash-advance/${this.paramsId}/realisation`,
        useRedirect: false,
        text: {
          swalTitle: `Create ${this.$t('apps.transactionsCashAdvance.singular.cashAdvance')} ${this.$t('apps.transactionsCashAdvance.actions.realisation')}  ?`,
          swalText: `${this.$t('info.notAbleRecover')}.`,
          successText: `${this.$t('feedback.createdNew')} ${this.$t('apps.transactionsCashAdvance.singular.cashAdvance')} ${this.$t('apps.transactionsCashAdvance.actions.realisation')}.`
        }
      })
        .then(() => {
          this.$router.push({
            name: 'apps-transactions-cash-advance-detail',
            params: { id: this.paramsId }
          })
        })

      this.showLoading = false
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
